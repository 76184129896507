




































import { Component, Mixins } from "vue-property-decorator";
import { Choice } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import Title from "@/components/common/Title.vue";
import InvoiceList from "@/components/invoicedashboard/InvoiceList.vue";
import InvoiceNoticeViewer from "@/components/invoicedashboard/InvoiceNoticeViewer.vue";
import { InvoiceNotice } from "#/model/bulletinboard";
import { InvoiceItem } from "@/views/invoicedashboard/types";
import * as appDate from "#/utility/appDate";

@Component({ components: { Title, InvoiceNoticeViewer, InvoiceList } })
export default class InvoiceDashboard extends Mixins(AxiosMixin, UtilMixin) {

  /** 変数 */

  /** 請求お知らせ */
  private invoiceNotices: InvoiceNotice[] = [];

  /** 請求情報 */
  private invoiceItems: InvoiceItem[] = [];

  /** 請求年 */
  private invoiceYear = 0;

  /** 請求年選択肢 */
  private invoiceYearChoice: Choice[] = [];

  /** フィルター用法人名リスト */
  private coNames: string[] = [];

  /** フィルター用事業所名リスト */
  private officeNames: string[] = [];

  /** getter */

  /** 請求お知らせエリア表示フラグ */
  private get ShowInvoiceNotice(): boolean {
    return this.invoiceNotices && this.invoiceNotices.length > 0;
  }

  created(): void {

    if (!this.MenuInfo.is_menu_invoice_dashboard) {
      this.$router.push("/");
    }

    this.invoiceYear = this.getCurrentYear();
    this.invoiceYearChoice = this.createInvoiceYearChoice();
    this.fetchInvoiceNotices();
    this.fetchInvoices();
  }

  /** 請求お知らせ取得 */
  private fetchInvoiceNotices() {
    this.postJsonCheck(window.base_url + "/api/invoicedashboard/notices/get", {}, res => {
      this.invoiceNotices = res.data.invoice_notices;
    });
  }

  /** 請求情報取得 */
  private fetchInvoices() {
    this.postJsonCheck(window.base_url + "/api/invoicedashboard/invoices/get", {"invoice_year": this.invoiceYear}, res => {
      this.invoiceItems = res.data.invoices;
      this.coNames = res.data.co_names;
      this.officeNames = res.data.office_names;
    });
  }

  /** 現在年を返却 */
  private getCurrentYear(): number {
    return Number(appDate.dayjsDate().format("YYYY"));
  }

  /** 請求年選択肢を作成 */
  private createInvoiceYearChoice(): Choice[] {
    // 2025年から現在年までを選択肢として用意
    const invoiceYearChoice: Choice[] = [];
    for (let i=this.getCurrentYear(); i>=2025; i--) {
      invoiceYearChoice.push({text: `${i}年`, value: i});
    }
    return invoiceYearChoice
  }

  /** 請求先情報へ遷移 */
  private moveToAgreementList() {
    this.$router.push({
      path: "/invoicedashboard/agreement/list",
    });
  }

  /** 請求書PDF取得 */
  private pdf(invoiceItem: InvoiceItem) {
    this.postJsonCheck(
      window.base_url + "/api/invoicedashboard/pdfs/get",
      {
        "invoice_yearmonth": invoiceItem.invoice_yearmonth,
        "office_id": invoiceItem.office_id
      },
      res => {
        window.open(res.data.path, "_blank");
      }
    );
  }
}
