






























import { Component, Mixins, Emit } from "vue-property-decorator";
import AppHeader from "@/components/main/AppHeader.vue";
import AppMenu from "@/components/main/AppMenu.vue";
import AppFooter from "@/components/main/AppFooter.vue";
import AppTitleHeader from "@/components/main/AppTitleHeader.vue";
import { namespace } from "vuex-class";
import * as master from "@/store/modules/master/types";
import AxiosMixin from "@/mixins/axiosMixin";
import { SideMenu } from "@/types";
import { MenuInfo } from "@/types/main";

const Master = namespace(master.name);

@Component({
  components: {
    AppHeader,
    AppMenu,
    AppFooter,
    AppTitleHeader
  }
})
export default class Main extends Mixins(AxiosMixin) {
  @Master.Mutation(master.SET_MASTER) setMaster!: (obj: unknown) => {};

  /** マスタデータ取得済みかどうか */
  protected isLoadMaster = false;

  /** サイドメニューの開閉状態 */
  private sideMenu: SideMenu = {
    isOpen: this.$vuetify.breakpoint.lgAndUp,
    isNarrow: false
  };

  /** 契約情報メニュー表示フラグ */
  private menuInfo: MenuInfo = {
    is_menu_agree_info: 0,
    is_use_ibow: 0,
    is_use_portal: 0,
    is_menu_invoice_dashboard: 0
  };

  private get title(): string {
    if (this.$route.name) {
      return this.$route.name;
    }
    return "";
  }

  created(): void {
    //マスタ取得
    this.postJsonCheck(window.base_url + "/api/master/get", {}, res => {
      if (res.data) {
        this.setMaster({
          loginUser: res.data.login_user
        });
        this.menuInfo = res.data.menu_info;
        this.isLoadMaster = true;
      }
    });
  }

  @Emit()
  private logout() {
    return;
  }

  private toggleSideOpen() {
    this.sideMenu.isOpen = !this.sideMenu.isOpen;
  }
  /** サイドメニューの幅変更 */
  private toggleSideNarrow() {
    this.sideMenu.isNarrow = !this.sideMenu.isNarrow;
  }
}
