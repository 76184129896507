

























































































































import { Component, Mixins } from "vue-property-decorator";
import Title from "@/components/common/Title.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";

/** 契約情報 */
export interface Agreement {
  /** ID */
  id: number;
  /** 企業コード */
  co_code: string;
  /** 法人名 */
  co_name: string;
  /** 郵便番号 */
  post_code: string;
  /** 住所 */
  address: string;
  /** 電話番号 */
  tel_no: string;
  /** FAX番号 */
  fax_no: string;
  /** 事業所情報リスト */
  offices: Office[];
}

/** 事業所情報 */
export interface Office {
  /** ID */
  id: number;
  /** KST番号 */
  kst_no: string;
  /** 事業所名 */
  name: string;
  /** 請求先 郵便番号 */
  invoice_post_code: string;
  /** 請求先 住所 */
  invoice_address: string;
  /** 請求先 名 */
  invoice_name: string;
  /** 銀行選択 */
  bank_selection: number;
  /** 銀行選択名 */
  bank_selection_name: string;
  /** 銀行種類(それ以外の金融機関選択時) */
  bank_type: string;
  /** 金融機関コード(それ以外の金融機関選択時) */
  bank_code: string;
  /** 銀行名(それ以外の金融機関選択時) */
  bank_name: string;
  /** 支店コード(それ以外の金融機関選択時) */
  branch_code: string;
  /** 支店名(それ以外の金融機関選択時) */
  branch_name: string;
  /** 支店種類(それ以外の金融機関選択時) */
  branch_type: string;
  /** 預金種別 */
  deposit_type: string;
  /** 口座番号(それ以外の金融機関選択時) */
  account_number: string;
  /** 通帳記号（ゆうちょ銀行） */
  japan_post_bank_code: string;
  /** 通帳番号（ゆうちょ銀行） */
  japan_post_bank_number: string;
  /** 口座名義人 */
  account_name: string;
  /** 口座名義人フリガナ */
  account_name_kana: string;
  /** アプラスコード */
  aplus_code: string;
}

@Component({ components: { Title } })
export default class AgreementList extends Mixins(AxiosMixin, UtilMixin) {

  /** 変数 */

  /** 契約情報リスト */
  private agreements: Agreement[] = [];

  /** 関数 */
  
  created(): void {

    if (!this.MenuInfo.is_menu_invoice_dashboard) {
      this.$router.push("/");
    }

    this.fetchAgreementList();
  }

  /** 契約情報取得 */
  private fetchAgreementList() {
    this.postJsonCheck(window.base_url + "/api/invoicedashboard/agreements/get", {}, res => {
      this.agreements = res.data.agreements;
    });
  }
}
